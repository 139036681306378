import React, { useState,useRef } from 'react';
import emailjs from '@emailjs/browser';
import NavSection from './../Components/NavSection';
import FooterSections from './../Components/FooterSections';

function Contact() {
  const form = useRef();
  const [submitButton , setSubmitButton] = useState('Submit');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_uxvv6o5', 'template_o3uwd6k', form.current, {
        publicKey: 'mKlmggssjTCLH0X6Y',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitButton('Message Sent')
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSubmitButton('Message Failed')
        },
      );
  };
  return (
    <div>
        <NavSection/>
        <div className='contact-areaform'>
            <div className='container'>
            <h2 className='section-heading'>Contact</h2>
            <p className='section-paragraph'>Leave us a little info, and we’ll be in touch.</p>
            
            {/* <form>
              <div className='form-container'>
                <div className='form-group'>
                  <label>Name</label>
                  <input type='text' placeholder='Enter Your Name'/>
                </div>
                <div className='form-group'>
                  <label>Email</label>
                  <input type='text' placeholder='Enter Your Email'/>
                </div>
                <div className='form-group'>
                  <label>Subject</label>
                  <textarea rows={9} placeholder='Subject'></textarea>
                </div>
                 <div>
                <button className='btn teal w-inline-block'>
                    Submit
                </button>
            </div> 
              </div>
            </form> */}
             <form ref={form} onSubmit={sendEmail}>
              <div className='form-container'>
                <div className='form-group'>
                  <label>Name</label>
                  <input type="text" name="user_name" placeholder='Enter Your Name' required/>
                </div>
                <div className='form-group'>
                  <label>Email</label>
                  <input type="email" name="user_email" placeholder='Enter Your Email' required/>
                </div>
                <div className='form-group'>
                  <label>Subject</label>
                  <textarea rows={9} name="message" placeholder='Subject' required/>
                </div>
                <button className='btn teal w-inline-block'>
                    {submitButton}
                </button>
                </div>
                </form>
            </div>
        </div>
        <FooterSections />
    </div>
  )
}

export default Contact