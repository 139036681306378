import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import SwiperCore, { EffectCoverflow, Pagination, Navigation,Autoplay } from 'swiper';
// import { slide_image_1, slide_image_2, slide_image_3, slide_image_4, slide_image_5,slide_image_6,slide_image_7 } from '../Imports/ImportImages';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { slide_image_1, slide_image_2, slide_image_3, slide_image_4, slide_image_5,slide_image_7 } from '../Imports/ImportImages';

import useWindowDimensions from './WindowDimensions';

// SwiperCore.use([EffectCoverflow, Pagination, Navigation,Autoplay]);

function GameSlider2() {
  const { width } = useWindowDimensions()
  var settings = {
    centerMode: true,
centerPadding: '240px',
touchThreshold:100,
dots : true,
slidesToShow: 3,
infinite: true,
speed : 800,
// autoplay : true,
infinite : true,
responsive: [
  {
    breakpoint: 1550,
    settings: {
      centerPadding: '200px',
    }
  },
  {
    breakpoint: 1370,
    settings: {
      centerPadding: '150px',
    }
  },
  {
    breakpoint: 768,
    settings: {
      arrows: false,
      centerMode: true,
      centerPadding: '40px',
      slidesToShow: 3
    }
  },
  {
    breakpoint: 480,
    settings: {
      arrows: false,
      centerMode: true,
      centerPadding: '40px',
      slidesToShow: 1
    }
  }
]
  };
  
  return (
  
    <div className='gameSlider'>
      <div className="container z-2">
        {/* <Swiper
          pagination={{ clickable: false }}
          // effect="slide"
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={false}
          loop={true}
          initialSlide={4}
          slidesPerView={width < 768 ? 1 : 5}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth:width < 768 ? 30 : 40 ,
            modifier: width < 768 ? 1 : 2 ,
          }}
          autoplay={{ delay: 3000 }}
          // spaceBetween={40}
          className="swiper_container"
          style={{ width: width < 768 ? '100%' : '100%' }}
        >
          <SwiperSlide>
            <img src={slide_image_1} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_2} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_3} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_4} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_5} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_6} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_7} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_3} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_4} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_5} alt="slide_image" />
          </SwiperSlide>
        </Swiper> */}
          <Slider {...settings}>
      <div>
        <img src={slide_image_1}/>
      </div>
      <div>
      <img src={slide_image_2}/>
      </div>
      <div>
      <img src={slide_image_3}/>
      </div>
      <div>
      <img src={slide_image_4}/>
      </div>
      <div>
      <img src={slide_image_5}/>
      </div>
      {/* <div>
      <img src={slide_image_6}/>
      </div> */}
      <div>
      <img src={slide_image_7}/>
      </div>
      
    </Slider>

      </div>
    </div>
  );
}

export default GameSlider2;





